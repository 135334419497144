import React from 'react';
import { Pages, usePage } from '../providers/page-provider';
import { useNavigate } from 'react-router';

export default function SimpleHeader() {
  const navigate = useNavigate();

  const headerColor = 'dark-mode-text-color';
  const headerClass = `${headerColor} header-top-base header-top-centered`;
  const titleClass = 'title-dark';

  return (
    <div className={`header-top ${headerClass}`} onClick={() => navigate(Pages.Home)}>
      <div className={titleClass}>
        <h1>Sunlight is Life</h1>
      </div>
    </div>
  );
}
