import React from 'react';
import { SunriseSun } from '../icons/sun-svg-icon';

import '../css/Splash.css';
import { Pages, usePage } from '../providers/page-provider';
import SimpleHeader from '../components/simple-header';
import { useNavigate } from 'react-router';

function SplashPage() {
  const navigate = useNavigate();

  function goToApp() {
    window.location.href = 'https://app.sunlightislife.com/';
  }

  return (
    <div className="App">
      <SimpleHeader />
      <div className="content-container">
        <div className="pitch-container">
          Discover how time-honored traditions connecting us to the sun align with ground
          breaking research in Circadian and Quantum Biology.
        </div>
        <div className="sunrise-container sunrise-animation" onClick={goToApp}>
          <SunriseSun radius={75} />
        </div>
        <div className="button-container">
          <button className="gradient-button button-large" onClick={goToApp}>
            Go to App
          </button>
          <button
            className="gradient-button button-large"
            onClick={() => navigate(Pages.Products)}
          >
            Recommended Products
          </button>
        </div>
      </div>
    </div>
  );
}

export default SplashPage;
