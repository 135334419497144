import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './css/App.css';
import SplashPage from './pages/splash-page';
import ProductsPage from './pages/products-page';
import { Pages } from './providers/page-provider';

export default function App() {
  // React Router version
  return (
    <Routes>
      <Route path="/" element={<SplashPage />} />
      <Route path={Pages.Products} element={<ProductsPage isLoaded={true} />} />
      {/* Redirect any unknown routes to home */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
