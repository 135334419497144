import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import ErrorBoundary from './components/error-boundary';
import { PageProvider } from './providers/page-provider';
import AuthProvider from './providers/auth-provider';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <AuthProvider>
      <BrowserRouter>
        <PageProvider>
          <App />
        </PageProvider>
      </BrowserRouter>
    </AuthProvider>
  </ErrorBoundary>,
);
