import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './auth-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { toggleLogging } from '../utils/logger';
import useBrowserInfo from '../hooks/use-browser-info';

// Define available pages
export const Pages = {
  Clock: 'clock',
  Uv: 'uv',
  Compass: 'compass',
  Settings: 'settings',
  Products: 'products',
  LearnMore: 'learn-more',
  Home: 'home',
  ToggleDebug: 'toggle-debug',
  ShowDebug: 'show-debug',
  Feedback: 'feedback',
};

const FeedbackItem = {
  key: Pages.Feedback,
  name: `Feedback/Feature Request`,
  link: Pages.Feedback,
  index: 8,
  siteNav: false,
  id: 'menu-item-feedback-feature-anchor',
};

const MenuItems = [
  { key: Pages.Home, name: 'Home', link: Pages.Home, index: 1, siteNav: false },
  { key: Pages.Clock, name: 'Schedule', link: Pages.Clock, index: 2, siteNav: true },
  {
    key: Pages.Uv,
    name: 'UV',
    link: Pages.Uv,
    index: 3,
    siteNav: true,
    id: 'uv-feature-anchor',
  },
  {
    key: Pages.Compass,
    name: 'Compass',
    link: Pages.Compass,
    index: 4,
    siteNav: true,
    id: 'compass-feature-anchor',
  },
  {
    key: Pages.LearnMore,
    name: 'Learn More',
    link: Pages.LearnMore,
    index: 5,
    siteNav: false,
    id: 'menu-item-learn-more-feature-anchor',
  },
  {
    key: Pages.Settings,
    name: 'Settings',
    link: Pages.Settings,
    index: 6,
    siteNav: false,
    id: 'menu-item-settings-feature-anchor',
  },
  {
    key: Pages.Products,
    name: 'Products',
    link: Pages.Products,
    index: 7,
    siteNav: false,
    id: 'menu-item-products-feature-anchor',
  },
];

// Create a Context for the Page Provider
const PageContext = createContext();

function addItem(menuItemList, newItem) {
  if (!menuItemList.some((item) => item.key === newItem.key)) {
    menuItemList.push(newItem);
  }
}

function removeItem(menuItemList, keyToRemove) {
  const index = menuItemList.findIndex((item) => item.key === keyToRemove);
  if (index !== -1) {
    menuItemList.splice(index, 1);
  }
}

const SiteNavMenuItems = MenuItems.filter((item) => item.siteNav);
const SiteHamburgerMenuItems = [...MenuItems, FeedbackItem];

export const PageProvider = ({ children }) => {
  const [activePage, setActivePage] = useState(Pages.Home);
  const [pageParams, setPageParams] = useState({});
  const { isStandalone } = useBrowserInfo();
  const [debug, setDebug] = useState(false);
  const [siteNavItems, setSiteNavItems] = useState(SiteNavMenuItems);
  const [siteMenuItems, setSiteMenuItems] = useState(SiteHamburgerMenuItems);
  const { user } = useAuth();

  // Function to navigate to a specific page
  const goToPage = (page, params = {}) => {
    console.log('goToPage', page, params);
    if (isValidPage(page)) {
      setActivePage(page);
      setPageParams(params);
    } else {
      setActivePage(page);
      setPageParams(params);
    }
  };

  // Function to get the current active page
  const getActivePage = () => {
    return activePage;
  };

  // Function to get current page parameters
  const getPageParams = () => {
    return pageParams;
  };

  // Function to check if the page is valid
  const isValidPage = (page) => {
    return Object.values(Pages).includes(page);
  };

  // Function to check if the page is active
  const isActivePage = (page) => {
    return activePage.includes(page);
  };

  // Function to toggle debug mode on or off
  const toggleDebug = () => {
    toggleLogging();
    setDebug((prevDebug) => !prevDebug);
  };

  return (
    <PageContext.Provider
      value={{
        goToPage,
        getActivePage,
        getPageParams,
        isValidPage,
        isActivePage,
        toggleDebug,
        debug,
        siteNavItems,
        siteMenuItems,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the PageContext
export const usePage = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
