import React from 'react';
import { ProductsSection } from '../components/products';
import BackBanner from '../components/back-banner';
import { useBackground } from '../hooks/use-background-animation';
import { SunTypes } from '../components/sun_path';
import AppHeader from '../components/app_header';
import { Pages, usePage } from '../providers/page-provider';
import SimpleHeader from '../components/simple-header';

const ProductsList = ({ targetProduct }) => {
  const productData = [
    {
      id: 'blue-blockers',
      label: 'Blue Blocker Glasses',
      description:
        'Blue light blocking glasses help regulate your circadian rhythm by filtering out specific wavelengths of light. Different tints are designed for different times of day, helping you optimize your sleep-wake cycle.',
      items: [
        {
          id: 'blue-blockers-budget',
          label: 'Budget',
          items: [
            {
              id: 'blue-blockers-budget-daytime',
              label: 'Daytime Indoor glasses',
              url: 'https://midwestredlighttherapy.com/product/night-prowler-yellow-lens-blue-light-blockers-daytime/affiliate/petercowan/',
            },
            {
              id: 'blue-blockers-budget-sundown',
              label: 'Sundown glasses',
              url: 'https://midwestredlighttherapy.com/product/night-prowler-orange-lens-blue-light-blockers/affiliate/petercowan/',
            },
            {
              id: 'blue-blockers-budget-nightfall',
              label: 'Nightfall glasses',
              url: 'https://midwestredlighttherapy.com/product/night-prowler-red-lens-blue-light-blockers/affiliate/petercowan/',
            },
            {
              id: 'blue-blockers-budget-more',
              label: 'More Budget Glasses',
              url: 'https://midwestredlighttherapy.com/product-category/blue-light-blocking-glasses/affiliate/petercowan/',
            },
          ],
        },
        {
          id: 'blue-blockers-premium',
          label: 'Premium and Prescription',
          items: [
            {
              id: 'blue-blockers-premium-sundown',
              label: 'Sundown Glasses',
              url: 'https://www.blockbluelight.com/products/sundown-taylor-glasses-black?ref=LIVINGENERGIES',
            },
            {
              id: 'blue-blockers-premium-nightfall',
              label: 'Nightfall Glasses',
              url: 'https://www.blockbluelight.com/products/nightfall-taylor-blue-blocking-glasses-grey?_pos=3&_fid=554a39126&_ss=c',
            },
            {
              id: 'blue-blockers-premium-more',
              label: 'More Premium Glasses',
              url: 'https://www.blockbluelight.com/collections/blue-light-glasses?ref=LIVINGENERGIES',
            },
          ],
        },
      ],
    },
    {
      id: 'red-light',
      label: 'Light Therapy',
      description:
        'Red light therapy delivers specific wavelengths of red and near-infrared light to your cells, supporting energy production, recovery, and overall health. Different devices are designed for different use cases and treatment areas.',
      items: [
        {
          id: 'red-light-torch',
          label: 'Torch',
          url: 'https://us.boncharge.com/products/bullet-red-light-device?rfsn=8007203.7b4802',
        },
        {
          id: 'red-light-portable',
          label: 'Portable',
          url: 'https://us.boncharge.com/products/mini-red-light_device?rfsn=8007203.7b4802',
        },
        {
          id: 'red-light-wrap',
          label: 'Body Wrap',
          url: 'https://us.boncharge.com/products/infrared-pemf-wrap?rfsn=8007203.7b4802',
        },
        {
          id: 'red-light-tabletop',
          label: 'Tabletop',
          url: 'https://www.blockbluelight.com/products/red-light-infrared-therapy-panel?ref=LIVINGENERGIES',
        },
        {
          id: 'red-light-half-body',
          label: 'Half Body',
          url: 'https://www.blockbluelight.com/products/red-light-therapy-powerpanel-mid?ref=LIVINGENERGIES',
        },
        {
          id: 'red-light-full-body',
          label: 'Full body',
          url: 'https://www.blockbluelight.com/products/red-light-therapy-powerpanel-max?ref=LIVINGENERGIES',
        },
        {
          id: 'red-light-more',
          label: 'More Red Light Panels',
          url: 'https://www.blockbluelight.com/collections/red-light-therapy-panels',
        },
        {
          id: 'therapy-light-full-spectrum-sad-panel',
          label: 'Full Spectrum SAD Panel with IR/UV',
          url: 'https://solshine.org/products/sol-photovites?ref=livingenergies',
        },
        {
          id: 'therapy-light-full-spectrum-sad-bulb',
          label: 'Full Spectrum SAD Bulb with IR/UV',
          url: 'https://solshine.org/products/solshine-biobulb?ref=livingenergies',
        },
        {
          id: 'therapy-light-full-spectrum-workstation-light',
          label: 'Full Spectrum Workstation Light - Day/Night Mode',
          url: 'https://getchroma.co/products/sky-portal?ref=bgjnflqr',
        },
        {
          id: 'therapy-light-vitamin-d',
          label: 'Vitamin D with Red Therapy Light',
          url: 'https://getchroma.co/products/d-light-vitamin-d-light-device-uvb-red-nir?ref=bgjnflqr',
        },
        {
          id: 'therapy-light-box-vitamin-d',
          label: 'Vitamin D Light Box',
          url: ' https://www.sperti.com/product/sperti-vitamin-d-light-box/?ref=10857',
        },
        {
          id: 'light-therapy-more',
          label: 'More Light Therapy Options',
          url: 'https://getchroma.co/?ref=bgjnflqr',
        },
      ],
    },
    {
      id: 'circadian-lighting',
      label: 'Circadian Lighting',
      description:
        "Proper lighting throughout the day and night helps maintain your natural circadian rhythm. Different color temperatures are designed to match the sun's natural cycle, supporting better sleep and daytime energy.",
      items: [
        {
          id: 'circadian-lighting-incandescent',
          label: 'Incandescents',
          items: [
            {
              id: 'circadian-lighting-incandescent-warm',
              label: 'Daytime Warm Bulb',
              url: 'https://healthlighting.com/r?id=5tjr1a',
            },
            {
              id: 'circadian-lighting-incandescent-bright',
              label: 'Daytime Bright White Bulb',
              url: 'https://healthlighting.com/r?id=3aj0on',
            },
            {
              id: 'circadian-lighting-three-way-bulb',
              label: 'Daytime Three-way White Bulb',
              url: 'https://healthlighting.com/r?id=djb3bg',
            },
            {
              id: 'circadian-lighting-incandescent-sundown',
              label: 'Sundown Bulb',
              url: 'https://amzn.to/3W6a0dW',
            },
            {
              id: 'circadian-lighting-incandescent-nightfall',
              label: 'Nightfall Bulb',
              url: 'https://healthlighting.com/r?id=k35l1r',
            },
            {
              id: 'circadian-lighting-incandescent-daytime-uva',
              label: 'Daytime UVA Incandescent Bulb',
              url: 'https://healthlighting.com/r?id=9sss57',
            },
            {
              id: 'circadian-lighting-incandescent-more',
              label: 'More Incandescent Bulbs',
              url: 'https://healthlighting.com/r?id=dobnr6',
            },
          ],
        },
        {
          id: 'circadian-lighting-led',
          label: 'LEDs',
          items: [
            {
              id: 'circadian-lighting-led-sundown',
              label: 'Sundown',
              url: 'https://www.blockbluelight.com/products/blue-blocking-sleep-lights?ref=LIVINGENERGIES',
            },
            {
              id: 'circadian-lighting-led-nightfall',
              label: 'Nightfall',
              url: 'https://www.blockbluelight.com/products/blue-blocking-red-sleep-bulb?ref=LIVINGENERGIES',
            },
            {
              id: 'circadian-lighting-led-motion',
              label: 'Motion Night Light',
              url: 'https://www.blockbluelight.com/products/red-light-motion-night-light?ref=LIVINGENERGIES',
            },
            {
              id: 'circadian-lighting-led-plugin',
              label: 'Plug-in Night Light',
              url: 'https://www.blockbluelight.com/products/plugin-red-night-light?ref=LIVINGENERGIES',
            },
            {
              id: 'circadian-lighting-led-more',
              label: 'More LEDs',
              url: ' https://www.blockbluelight.com/collections/sleep-enhancing-lighting?ref=LIVINGENERGIES',
            },
          ],
        },
      ],
    },
  ];

  return <ProductsSection targetProduct={targetProduct} productData={productData} />;
};

const ProductsPage = ({ isLoaded }) => {
  useBackground(SunTypes.Twilight);
  const { getPageParams, goToPage } = usePage();
  const { targetProduct } = getPageParams();
  console.log('Products Page');
  return (
    <div className="App">
      {targetProduct && <BackBanner onClick={() => goToPage(Pages.Home)} />}
      <SimpleHeader />
      <div className="products-content">
        <div className="settings-container">
          <ProductsList targetProduct={targetProduct} />
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
